<template>
  <div class="platformManagerForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="应用名称"
        :rules="[
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ]"
        prop="modelTitle"
      >
        <v-input
          placeholder="请输入应用名称"
          :maxlength="8"
          v-model="form.modelTitle"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="应用图标"
        :rules="[
          {
            required: true,
            message: '请上传应用图标',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="modelIconPic"
      >
        <v-upload
          :limit="1"
          :imgUrls.sync="form.modelIconPic"
          tips="建议图标120px*120px"
        />
      </el-form-item>
      <el-form-item
        label="封面图"
        :rules="[
          {
            required: false,
            message: '请上传封面图',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="coverPic"
      >
        <v-upload
          :limit="1"
          :imgUrls.sync="form.coverPic"
          tips="建议上传500px*500px"
        />
      </el-form-item>
      <el-form-item
        label="介绍图片"
        :rules="[
          {
            required: false,
            message: '请上传介绍图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="descPic"
      >
        <v-upload
          :limit="9"
          :imgUrls.sync="form.descPic"
          tips="建议上传150px*300px"
        />
      </el-form-item>
      <el-form-item
        label="绑定租户"
        :rules="[{ required: true, message: '请选择租户', trigger: 'blur' }]"
        prop="tenantId"
      >
        <v-select
          :options="tenantList"
          v-model="form.tenantId"
          :disabled="isEdit && form.appType != 2"
        />
      </el-form-item>
      <el-form-item
        label="应用分类"
        :rules="[
          { required: true, message: '请选择应用分类', trigger: 'blur' },
        ]"
        prop="categoryId"
      >
        <v-select
          :options="kindsList"
          :disabled="isEdit && form.appType != 2"
          v-model="form.categoryId"
        />
      </el-form-item>
      <el-form-item
        label="应用简介"
        :rules="[
          { required: true, message: '请输入应用简介', trigger: 'blur' },
        ]"
        prop="modelDesc"
      >
        <v-input
          type="textarea"
          placeholder="请输入应用简介"
          v-model="form.modelDesc"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="是否需要绑定园区"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="targetIsBindCommunity"
      >
        <v-radio
          :radioObj="bindCommunityObj"
          v-model="form.targetIsBindCommunity"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="是否省级应用"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isProvinceApp"
      >
        <v-radio
          disabled
          :radioObj="provinceAppObj"
          v-model="form.isProvinceApp"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="是否新应用"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isNew"
      >
        <v-radio :radioObj="isNewObj" v-model="form.isNew"></v-radio>
      </el-form-item>
      <el-form-item
        label="是否十二有应用"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isShieryou"
      >
        <v-radio :radioObj="isShieryouObj" v-model="form.isShieryou"></v-radio>
      </el-form-item>
      <el-form-item
        label="是否首页展示"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isHomeShow"
      >
        <v-radio
          :radioObj="isHomeRadioObj"
          v-model="form.isHomeShow"
          @change="change"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="是否需要实名"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="targetIsRealName"
      >
        <v-radio
          :disabled="isEdit && form.appType != 2"
          :radioObj="isRealRadioObj"
          v-model="form.targetIsRealName"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="应用码"
        :rules="[{ required: true, message: '请输入应用码', trigger: 'blur' }]"
        prop="modelKey"
      >
        <v-input
          placeholder="请输入应用码"
          v-model="form.modelKey"
          :maxlength="30"
          :disabled="isEdit && form.appType != 2"
          :width="250"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="应用分类"
        :rules="[
          { required: true, message: '请选择应用分类', trigger: 'blur' },
        ]"
        prop="targetType"
      >
        <v-select
          :options="pagesType"
          :disabled="isEdit && form.appType != 2"
          v-model="form.targetType"
        />
      </el-form-item>
      <el-form-item
        label="是否增加数据埋点"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="targetIsBuriedPoint"
      >
        <v-radio
          :radioObj="radioObj"
          :disabled="isEdit && form.appType != 2"
          v-model="form.targetIsBuriedPoint"
          @change="change"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="是否高频应用"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isPopular"
      >
        <v-radio :radioObj="popularOps" v-model="form.isPopular"></v-radio>
      </el-form-item>
      <el-form-item
        label="数据埋点类型"
        v-if="form.targetIsBuriedPoint == 1 && form.appType == 2"
        :rules="[{ required: false, message: '请选择', trigger: 'blur' }]"
        prop="targetBuriedPointTypeId"
      >
        <v-select
          :options="buriedPointList"
          v-model="form.targetBuriedPointTypeId"
          :disabled="isEdit && form.appType != 2"
        />
      </el-form-item>
      <el-form-item
        label="数据埋点类型"
        v-if="form.targetIsBuriedPoint == 1 && form.appType != 2"
        :rules="[{ required: false, message: '请选择', trigger: 'blur' }]"
        prop="targetBuriedPointTypeId"
      >
        <span>{{ form.targetBuriedPointTypeTitle }}</span>
      </el-form-item>
      <template v-if="form.targetType == 1">
        <el-form-item
          label="小程序页面路径"
          :rules="[
            {
              required: true,
              message: '请输入小程序页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入小程序页面路径"
            v-model="form.targetUrl"
            :disabled="isEdit && form.appType != 2"
            :width="250"
          ></v-input
          ><span class="info">(例如："/pages/index/index")</span>
        </el-form-item>
      </template>
      <template v-if="form.targetType == 2">
        <el-form-item
          label="h5页面路径"
          :rules="[
            {
              required: true,
              message: '请输入h5页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入h5页面路径"
            v-model="form.targetUrl"
            :width="350"
            :disabled="isEdit && form.appType != 2"
          ></v-input
          ><span class="info">(例如："/futureCreateWork/home")</span>
        </el-form-item>
      </template>
      <template v-if="form.targetType == 3">
        <el-form-item
          label="第三方h5页面路径"
          :rules="[
            {
              required: true,
              message: '请输入第三方h5页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入第三方h5页面路径"
            v-model="form.targetUrl"
            :disabled="isEdit && form.appType != 2"
            :width="350"
          ></v-input
          ><span class="info"
            >(例如："https://dev.zhuneng.cn/zlb-h5-yljk/pages/entrance/thirdParty")</span
          >
        </el-form-item>
        <el-form-item
          label="是否需要授权"
          :rules="[{ required: false, message: '请选择', trigger: 'blur' }]"
          prop="targetIsAuth"
        >
          <v-radio
            :radioObj="radioObj"
            v-model="form.targetIsAuth"
            :disabled="isEdit && form.appType != 2"
            @change="change"
          ></v-radio>
        </el-form-item>
        <el-form-item
          v-if="form.targetIsAuth == 1"
          label="授权码"
          :rules="[
            { required: false, message: '请输入授权码', trigger: 'blur' },
          ]"
          prop="targetAuthCode"
        >
          <v-input
            placeholder="请输入授权码"
            v-model="form.targetAuthCode"
            :disabled="isEdit && form.appType != 2"
            :width="250"
          ></v-input>
          <span class="info">(例如："WJW代表XX健康的授权码")</span>
        </el-form-item>
      </template>
      <template v-if="form.targetType == 4">
        <el-form-item
          label="第三方小程序appid"
          :rules="[
            {
              required: false,
              message: '请输入第三方小程序appid',
              trigger: 'blur',
            },
          ]"
          prop="targetAppid"
        >
          <v-input
            placeholder="请输入第三方小程序appid"
            v-model="form.targetAppid"
            :disabled="isEdit && form.appType != 2"
            :width="250"
          ></v-input
          ><span class="info">(例如："wx0cd4b682f7e7f72f")</span>
        </el-form-item>
        <el-form-item
          label="第三方小程序页面路径"
          :rules="[
            {
              required: form.targetType == 4 ? false : true,
              message: '请输入第三方小程序页面路径',
              trigger: 'blur',
            },
          ]"
          prop="targetUrl"
        >
          <v-input
            placeholder="请输入第三方小程序页面路径"
            v-model="form.targetUrl"
            :disabled="isEdit && form.appType != 2"
            :width="350"
          ></v-input
          ><span class="info"
            >(例如："/pages/home/home?XKHospitalCode=XK33021201fGdxixsF")</span
          >
        </el-form-item>
        <el-form-item
          label="是否需要授权"
          :rules="[{ required: false, message: '请选择', trigger: 'blur' }]"
          prop="targetIsAuth"
        >
          <v-radio
            :radioObj="radioObj"
            v-model="form.targetIsAuth"
            :disabled="isEdit && form.appType != 2"
            @change="change"
          ></v-radio>
        </el-form-item>
        <el-form-item
          v-if="form.targetIsAuth == 1"
          label="授权码"
          :rules="[
            { required: false, message: '请输入授权码', trigger: 'blur' },
          ]"
          prop="targetAuthCode"
        >
          <v-input
            placeholder="请输入授权码"
            v-model="form.targetAuthCode"
            :disabled="isEdit && form.appType != 2"
            :width="250"
          ></v-input>
          <span class="info">(例如："WJW代表XX健康的授权码")</span>
        </el-form-item>
      </template>
      <el-form-item
        label="额外参数"
        :rules="[
          { required: false, message: '请输入额外参数', trigger: 'blur' },
        ]"
        prop="targetExtraData"
      >
        <div class="extraData">
          <div id="jsoneditor"></div>
        </div>
      </el-form-item>

      <el-form-item
        label="入口类型"
        :rules="[
          { required: true, message: '请选择入口类型', trigger: 'blur' },
        ]"
        prop="sourceType"
      >
        <v-select
          clearable
          placeholder="请选择"
          :options="inType"
          :disabled="isEdit && form.appType != 2"
          v-model="form.sourceType"
        />
      </el-form-item>

      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入排序"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="对标省应用"
        :rules="[
          { required: false, message: '请选择入口类型', trigger: 'blur' },
        ]"
        prop="standardAppCode"
      >
        <v-select
          clearable
          placeholder="请选择"
          :options="standardAppCodeList"
          v-model="form.standardAppCode"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import JSONEditor from "jsoneditor";
import {
  addTenantAppUrl,
  getBuriedPointUrl,
  tenantAppDetailUrl,
  kindsListUrl,
  tenantListUrl,
  getDTenantList,
} from "./api";
import { pagesType, inType, standardAppCodeList } from "./map";
export default {
  name: "platformManagerForm",
  data() {
    let _this = this;
    return {
      standardAppCodeList,
      tenantList: [],
      kindsList: [],
      inType,
      isShieryouObj: [
        { name: 0, value: "否" },
        { name: 1, value: "是" },
      ],
      isNewObj: [
        { name: 0, value: "否" },
        { name: 1, value: "是" },
      ],
      bindCommunityObj: [
        { name: 0, value: "否" },
        { name: 1, value: "是" },
      ],
      isRealRadioObj: [
        { name: "0", value: "否" },
        { name: "1", value: "是" },
      ],
      provinceAppObj: [
        { name: 0, value: "否" },
        { name: 1, value: "是" },
      ],
      isHomeRadioObj: [
        { name: "0", value: "否" },
        { name: "1", value: "是" },
      ],
      radioObj: [
        { name: "0", value: "否" },
        { name: "1", value: "是" },
      ],
      popularOps: [
        { name: 0, value: "否" },
        { name: 1, value: "是" },
      ],
      chapterList: [],
      submitConfig: {
        queryUrl: tenantAppDetailUrl,
        submitUrl: addTenantAppUrl,
      },
      teacherSignList: [],
      form: {
        targetIsBindCommunity: 0,
        isShieryou: 0,
        isProvinceApp: 0,
        isNew: 0,
        targetBuriedPointTypeTitle: "",
        appType: "",
        coverPic: "",
        descPic: "",
        targetIsRealName: "0",
        tenantId: "",
        id: "",
        categoryId: "",
        modelTitle: "",
        modelIconPic: "",
        modelDesc: "",
        targetType: "",
        sortIndex: 1,
        sourceType: "",
        modelKey: "",
        targetUrl: "",
        targetAppid: "",
        targetIsBuriedPoint: "0",
        isPopular: 0, // 是否为高频应用：1是，0否
        targetBuriedPointTypeId: "",
        isHomeShow: "1",
        targetIsAuth: "0",
        targetAuthCode: "",
        targetExtraData: {},
        insertUser: "",
        standardAppCode: "",
        updateUser: "",
      },
      buriedPointList: [],
      pagesType,
      jsonData: {
        a: 1,
      },
      editor: null,
    };
  },
  computed: {
    isEdit() {
      return this.form.id ? true : false;
    },
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
      this.form.updateUser = this.$store.state.app.userInfo.id;
    } else {
      this.form.insertUser = this.$store.state.app.userInfo.id;
    }
    this.initJsonEditer();
    this.getKindsList();
    // this.getTenantList();
    this.getBuriedPointList();
    this.getDTenantList();
    this.$setBreadList(id ? "编辑" : "新增");
  },
  destroyed() {},
  methods: {
    //获取社区列表
    async getDTenantList() {
      const res = await this.$axios.get(getDTenantList);
      if (res.code == 200) {
        const { data } = res;
        data.forEach((item) => {
          item.value = item.tenantId;
          item.label = item.districtName;
        });
        this.tenantList = data;
      }
    },
    // getTenantList() {
    //   let params = {};
    //   this.tenantList = [];
    //   this.$axios.get(`${tenantListUrl}`).then((res) => {
    //     if (res.code == 200) {
    //       res.data.forEach((item) => {
    //         item.label = item.tenantName;
    //         item.value = item.tenantId;
    //         this.tenantList.push(item);
    //       });
    //     } else {
    //     }
    //   });
    // },
    async getKindsList() {
      this.kindsList = [];
      let res = await this.$axios.get(`${kindsListUrl}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            item.label = item.title;
            item.value = item.id;
            this.kindsList.push(item);
          });
        }
      }
    },
    onChangeJSON(json) {
      console.log(json);
    },
    initJsonEditer() {
      this.$nextTick(() => {
        let _this = this;
        let container = document.getElementById("jsoneditor");
        let options = {
          mode: "code",
          indentation: 2,
          search: true,
          enableTransform: false,
        };
        this.editor = new JSONEditor(container, options);
        this.editor && this.editor.set(this.form.targetExtraData);
      });
    },
    getCode() {
      console.log(this.jsonData, this.editor.get());
    },
    async getBuriedPointList() {
      this.buriedPointList = [];
      let res = await this.$axios.get(`${getBuriedPointUrl}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            item.label = item.typeName;
            item.value = item.id;
            this.buriedPointList.push(item);
          });
        }
      }
    },
    change() {},

    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      var response = this.editor.validate();
      response.then(
        (res) => {
          if (res.length) {
            this.$message("请正确书写额外参数");

            return;
          }
        },
        () => {}
      );

      if (this.editor.get()) {
        this.form.targetExtraData = JSON.stringify(this.editor.get());
        console.log(this.form.targetExtraData, "+++++=");
      }

      return true;
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.form.id = data.id;
      this.form.isHomeShow = String(data.isHomeShow);
      this.form.targetIsBuriedPoint = String(data.targetIsBuriedPoint);
      this.form.targetIsAuth = String(data.targetIsAuth);
      this.form.targetIsRealName = String(data.targetIsRealName);
      if (data.targetBuriedPointTypeId) {
        this.form.targetBuriedPointTypeId = String(
          data.targetBuriedPointTypeId
        );
      }
      if (
        !data.targetExtraData ||
        data.targetExtraData == "" ||
        data.targetExtraData == undefined
      ) {
        console.log(data.targetExtraData, "++++++");
        this.form.targetExtraData = {};
      } else {
        this.form.targetExtraData = JSON.parse(data.targetExtraData);
      }
      this.editor && this.editor.set(this.form.targetExtraData);
    },
  },
};
</script>
<style lang="less" scoped>
.platformManagerForm {
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 180px !important;
  }
  /deep/ textarea {
    height: 150px;
  }
  .info {
    margin-left: 10px;
  }
  .extraData {
    width: 600px;
    height: 500px;
    #jsoneditor {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style scoped>
@import "~jsoneditor/dist/jsoneditor.min.css";
.IotAgentServiceConfig-wrapper {
  background-color: white;
  overflow: hidden;
}

.search-wrapper {
  margin: 20px;
  display: flex;
}

.pull-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

#jsoneditor {
  margin: 20px 20px 20px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 160px);
}

#jsoneditor >>> .jsoneditor-menu {
  background-color: #409eff;
  border-bottom: 1px solid #409eff;
}

#jsoneditor >>> .jsoneditor.jsoneditor-mode-text {
  border: thin solid #409eff;
}

#jsoneditor >>> .jsoneditor-poweredBy {
  display: none;
}
</style>
